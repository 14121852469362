import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import emptyImg from "../../assets/empty.png";
import {
    Link,
    Card,
    CardMedia,
    Stack,
    Typography,
    Container,
    CardContent,
    Paper,
    Badge,
} from "@mui/material";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import weatherBrandLogo from "../../assets/weatherBrandLogo.png";
import CircularProgress from "@mui/material/CircularProgress";
import Carousel from "react-material-ui-carousel";

import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils/CookieFunction";
import {
    getWeatherData,
    getReadImgfile,
    getCamFourFiles,
    getTimeLapsImgs,
} from "../../apis/apiProvider";
import { Timelapse } from "./TimeLapse";
import DarkButton from "../../components/common-component/DarkButton";
import { useFullscreen } from "../../utils/useFullscreen";
const hostPath = "https://denalicams.com";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: { xs: "95%", sm: "max-content" },
    maxWidth: "100vw", // Prevents unnecessary extra space
    maxHeight: "90vh", // Ensures it fits within the viewport
    bgcolor: "background.paper",
    border: {
        md: "20px solid #fff",
        xs: "10px solid #fff",
        lg: "25px solid #fff",
    },
    boxShadow: 24,
    p: 0, // Remove padding to eliminate extra space
    borderRadius: 2,
    overflow: "hidden",
};

const buttonStyle = {
    padding: "3px",
    color: "white",
    textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};

const mainButtonStyle = {
    color: "white",
    textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};
const carouselTxt = {
    textAlign: "center",
    position: "absolute",
    bottom: "20px",
    width: "100%",
    color: "white",
    textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};
export const Home = () => {
    const [enlargeOpen, setEnlargeOpen] = React.useState(false);

    const [isLoadedData, setIsLoadedData] = React.useState(false);
    const [wDaydata, setWDayData] = React.useState(new Date());
    const [tempData, setTempData] = React.useState(0);
    const [dewData, setDewData] = React.useState(0);
    const [winddir, setWinddir] = React.useState(0);
    const [windGuest, setWindGuest] = React.useState(0);
    const [windSpeed, setWindSpeed] = React.useState(0);
    const [barometer, setBarometer] = React.useState(0);
    const [baromabsin, setBaromabsin] = React.useState(0);
    const [densAltitude, setDensAltitude] = React.useState(0);
    const [tempinf, setTempinf] = React.useState(0);
    const [isUpBarometer, setIsUpBarometer] = React.useState(false);
    const [modalImg, setModalImg] = React.useState(emptyImg);
    const [image1Path, setImage1Path] = React.useState(emptyImg);
    const [image2Path, setImage2Path] = React.useState(emptyImg);
    const [image3Path, setImage3Path] = React.useState(emptyImg);
    const [image4Path, setImage4Path] = React.useState(emptyImg);
    const [camTimeVal, setCamTimeVal] = React.useState("");
    const [timelapsImgs, setTimelapsImgs] = React.useState([]);
    const [timelapsOpen, setTimelapsOpen] = React.useState(false);
    const [imagesTest, setImagesTest] = React.useState();
    const [playc, setPlayc] = React.useState(true);
    const [activeIndex, setActiveIndex] = React.useState(0);
    // const [isFullscreen, setIsFullscreen] = React.useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        setEnlargeOpen(false);
    };
    React.useEffect(() => {
        loadAllData();
        const loadInterval = setInterval(() => {
            loadAllData();
        }, 100000);
        return () => clearInterval(loadInterval);
    }, []);
    const loadAllData = async () => {
        loadWeatherData();
        loadCamData();
    };
    // const loadCamData = async () => {
    //   const imagesData = await getCamFourFiles("cam4");
    //   if (imagesData['resultDataLive']) {
    //     setImagesTest(true);
    //     console.log("imagesData-------------", imagesData['resultDataLive']);
    //     console.log("imagesLiveData----------", imagesTest);
    //   }

    //   if (!imagesData) return;
    //   // setImagePath(`${hostPath}${imagesData.path}/${imagesData.imagelist[0].name}`);
    //   if (imagesData.resultData.cam1) {
    //     setImage1Path(`${hostPath}${imagesData.resultData.cam1}`);
    //   }
    //   if (imagesData.resultData.cam2) {
    //     setImage2Path(`${hostPath}${imagesData.resultData.cam2}`);
    //   }
    //   if (imagesData.resultData.cam3) {
    //     setImage3Path(`${hostPath}${imagesData.resultData.cam3}`);
    //   }
    //   if (imagesData.resultData.cam4) {
    //     setImage4Path(`${hostPath}${imagesData.resultData.cam4}`);
    //   }
    // };

    // console.log(imagesLive.cam1);

    // React.useEffect(() => {
    //   // console.log("imagesLiveData (updated)----------", imagesTest);
    // }, [imagesTest]);

    const loadCamData = async () => {
        try {
            const imagesData = await getCamFourFiles("cam4");

            if (imagesData?.resultDataLive) {
                setImagesTest(imagesData.resultDataLive);
            }

            if (!imagesData) return;

            if (imagesData.resultData?.cam1) {
                setImage1Path(`${hostPath}${imagesData.resultData.cam1}`);
            }
            if (imagesData.resultData?.cam2) {
                setImage2Path(`${hostPath}${imagesData.resultData.cam2}`);
            }
            if (imagesData.resultData?.cam3) {
                setImage3Path(`${hostPath}${imagesData.resultData.cam3}`);
            }
            if (imagesData.resultData?.cam4) {
                setImage4Path(`${hostPath}${imagesData.resultData.cam4}`);
            }
        } catch (error) {
            console.error("Error loading camera data:", error);
        }
    };

    const loadWeatherData = async () => {
        const weatherData = await getWeatherData();
        if (!weatherData) return;
        const dateVal = new Date(weatherData[0].date);
        setWDayData(dateVal);
        setTempData(weatherData[0].tempf);
        setDewData(weatherData[0].dewPoint);
        setWinddir(weatherData[0].winddir);
        setWindGuest(weatherData[0].windgustmph);
        setWindSpeed(weatherData[0].windspeedmph);
        setBarometer(weatherData[0].baromrelin);
        setBaromabsin(weatherData[0].baromabsin);
        setTempinf(weatherData[0].tempinf);
        const isUp =
            weatherData[0].baromabsin - weatherData[0].baromrelin > 0
                ? true
                : false;
        setIsUpBarometer(isUp);
        setIsLoadedData(true);
    };

    const handleLogout = () => {
        removeCookie("token");
        localStorage.removeItem("previousUrl");
        navigate("/login");
    };
    const handleOpen = (camNum) => {
        switch (camNum) {
            case 1:
                setModalImg(image1Path);
                break;
            case 2:
                setModalImg(image2Path);
                break;
            case 3:
                setModalImg(image3Path);
                break;
            case 4:
                setModalImg(image4Path);
                break;
            default:
                break;
        }

        setEnlargeOpen(true);
    };

    const getTimeLapsImageArr = async (camStr) => {
        if (camStr !== camTimeVal) {
            setTimelapsImgs([]);
            // const nowDate = new Date();
            // const yearNum = nowDate.getFullYear();
            // const monthNum = nowDate.getMonth() + 1;
            // const dayNum = nowDate.getDate();
            // const path = `${camStr}/${yearNum.toString().padStart(2, "0")}/${monthNum
            //   .toString()
            //   .padStart(2, "0")}/${dayNum.toString().padStart(2, "0")}`;

            const url = new URL(camStr);
            let pathOnly = url.pathname + url.search + url.hash;
            const parts = pathOnly.split("/");
            const pathOnly1 = parts.slice(2, 6).join("/"); // Extract cam4, 2025, 02, 10

            const imgRes = await getTimeLapsImgs(pathOnly1);
            if (!imgRes) return;
            imgRes.sort((a, b) => {
                const aDate = new Date(a.modified);
                const bDate = new Date(b.modified);
                return bDate.getTime() - aDate.getTime();
            });
            setTimelapsImgs(imgRes);
            handleTimelapsOpen();
        } else if (timelapsImgs.length < 1) {
            const nowDate = new Date();
            const yearNum = nowDate.getFullYear();
            const monthNum = nowDate.getMonth() + 1;
            const dayNum = nowDate.getDate();
            const path = `${camStr}/${yearNum
                .toString()
                .padStart(2, "0")}/${monthNum
                .toString()
                .padStart(2, "0")}/${dayNum.toString().padStart(2, "0")}`;
            const imgRes = await getTimeLapsImgs(path);
            if (!imgRes) return;
            imgRes.sort((a, b) => {
                const aDate = new Date(a.modified);
                const bDate = new Date(b.modified);
                return bDate.getTime() - aDate.getTime();
            });
            setTimelapsImgs(imgRes);
            handleTimelapsOpen();
        } else {
            handleTimelapsOpen();
        }
        setCamTimeVal(camStr);
    };
    const handleTimelapsOpen = () => {
        setTimelapsOpen(true);
        setActiveIndex(0);
        setPlayc(true);
    };
    const handleTimelapsClose = () => {
        setTimelapsOpen(false);
    };
    /*for fullscreen function */

    const { isFullscreen, enterFullscreen, exitFullscreen } = useFullscreen();

    /*get density altitude*/

    React.useEffect(() => {
        const getDensityAltitude = () => {
            const pressureAltitude = (29.92 - baromabsin) * 1000;
            const temperatureCelsius = (tempData - 32) * 0.5556;
            const densityAltitude =
                pressureAltitude + 120 * (temperatureCelsius - 3.49);
            setDensAltitude(densityAltitude);
        };
        getDensityAltitude();
    }, [baromabsin, densAltitude, tempinf]);

    return (
        <div style={{ minHeight: "100vh" }}>
            {/* <Container> */}

            <Box
                sx={{ padding: "0.8rem", height: "96vh" }}
                className="home-container"
            >
                <Grid container>
                    <Grid
                        item
                        md={8}
                        xs={12}
                        order={{ md: 1, sm: 2, xs: 2 }}
                        height={"96vh"}
                        className="home-grid"
                    >
                        <Box height={"100%"}>
                            <Grid container spacing={2} height={"100%"}>
                                {/* <Grid item md={6} xs={12}>
                  <Card
                    sx={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "45vh",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="Yosemite National Park"
                      image={image1Path}
                      onClick={() => {
                        handleOpen(1);
                      }}
                    ></CardMedia>
                    <CardContent
                      sx={{
                        padding: "0px !important",
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={3}
                        p={1}
                        useFlexGap
                      >
                        <Stack direction="column" spacing={0.5} useFlexGap>
                          <Typography
                            variant="body1"
                            sx={{
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "white",
                              textShadow:
                                " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;",
                            }}
                          >
                            Camera 1 NW
                          </Typography>
                        </Stack>
                        <Stack direction={"row"}>
                          <Stack
                            direction="column"
                            spacing={0.5}
                            marginX={1}
                            useFlexGap
                          >
                            <Button
                              onClick={() => {
                                handleOpen(1);
                              }}
                              variant="outlined"
                              size="small"
                              sx={buttonStyle}
                            >
                              ENLARGE
                            </Button>
                          </Stack>
                          <Stack
                            direction="column"
                            spacing={0.5}
                            marginX={1}
                            useFlexGap
                          >
                            <Button
                              onClick={() => {
                                getTimeLapsImageArr("cam1");
                              }}
                              variant="outlined"
                              size="small"
                              sx={buttonStyle}
                            >
                              TIME-LAPSE
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid> */}
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    maxHeight={"50vh"}
                                    className="home-grid-item"
                                >
                                    <Card
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                            maxHeight: "50vh",
                                        }}
                                    >
                                        {imagesTest?.cam1 === false && (
                                            <Badge
                                                color="error"
                                                sx={{
                                                    position: "absolute",
                                                    top: 20,
                                                    left: 60,
                                                    "& .MuiBadge-badge": {
                                                        padding: "0px",
                                                        backgroundColor:
                                                            "transparent",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                badgeContent={
                                                    <Box
                                                        sx={{
                                                            width: "105px",
                                                            background:
                                                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                                                            color: "white",
                                                            padding: "6px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            borderRadius: "4px",
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        Not Current Image
                                                    </Box>
                                                }
                                            />
                                        )}

                                        {/* Image */}
                                        <CardMedia
                                            component="img"
                                            style={{
                                                objectFit: "cover",
                                                objectPosition: "right top",
                                            }}
                                            height="100%"
                                            alt="Yosemite National Park"
                                            image={image1Path}
                                            onClick={() => {
                                                handleOpen(1);
                                            }}
                                        ></CardMedia>

                                        {/* Content */}
                                        <CardContent
                                            sx={{
                                                padding: "0px !important",
                                                position: "absolute",
                                                bottom: 0,
                                                width: "100%",
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={3}
                                                p={1}
                                                useFlexGap
                                            >
                                                {/* Camera Title */}
                                                <Stack
                                                    direction="column"
                                                    spacing={0.5}
                                                    useFlexGap
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            color: "white",
                                                            textShadow:
                                                                "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;",
                                                        }}
                                                    >
                                                        Camera 1 NE
                                                    </Typography>
                                                </Stack>

                                                {/* Buttons */}
                                                <Stack direction={"row"}>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                handleOpen(1);
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            ENLARGE
                                                        </Button>
                                                    </Stack>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                getTimeLapsImageArr(
                                                                    image1Path
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            TIME-LAPSE
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    height={"50vh"}
                                    className="home-grid-item"
                                >
                                    <Card
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                            maxHeight: "50vh",
                                        }}
                                    >
                                        {imagesTest?.cam2 === false && (
                                            <Badge
                                                color="error"
                                                sx={{
                                                    position: "absolute",
                                                    top: 20,
                                                    left: 60,
                                                    "& .MuiBadge-badge": {
                                                        padding: "0px",
                                                        backgroundColor:
                                                            "transparent",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                badgeContent={
                                                    <Box
                                                        sx={{
                                                            width: "105px",
                                                            background:
                                                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                                                            color: "white",
                                                            padding: "6px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            borderRadius: "4px",
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        Not Current Image
                                                    </Box>
                                                }
                                            />
                                        )}
                                        <CardMedia
                                            component="img"
                                            style={{
                                                objectFit: "cover",
                                                objectPosition: "right top",
                                            }}
                                            height="100%"
                                            alt="Yosemite National Park"
                                            image={image2Path}
                                            onClick={() => {
                                                handleOpen(2);
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                padding: "0px !important",
                                                position: "absolute",
                                                bottom: 0,
                                                width: "100%",
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={3}
                                                p={1}
                                                useFlexGap
                                            >
                                                <Stack
                                                    direction="column"
                                                    spacing={0.5}
                                                    useFlexGap
                                                >
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            color: "white",
                                                            textShadow:
                                                                " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;",
                                                        }}
                                                    >
                                                        Camera 2 SE
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={"row"}>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                handleOpen(2);
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            ENLARGE
                                                        </Button>
                                                    </Stack>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                getTimeLapsImageArr(
                                                                    image2Path
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            TIME-LAPSE
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    height={"50vh"}
                                    className="home-grid-item"
                                >
                                    <Card
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                            maxHeight: "50vh",
                                        }}
                                    >
                                        {imagesTest?.cam3 === false && (
                                            <Badge
                                                color="error"
                                                sx={{
                                                    position: "absolute",
                                                    top: 20,
                                                    left: 60,
                                                    "& .MuiBadge-badge": {
                                                        padding: "0px",
                                                        backgroundColor:
                                                            "transparent",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                badgeContent={
                                                    <Box
                                                        sx={{
                                                            width: "105px",
                                                            background:
                                                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                                                            color: "white",
                                                            padding: "6px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            borderRadius: "4px",
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        Not Current Image
                                                    </Box>
                                                }
                                            />
                                        )}
                                        <CardMedia
                                            component="img"
                                            style={{
                                                objectFit: "cover",
                                                objectPosition: "right top",
                                            }}
                                            height="100%"
                                            alt="Yosemite National Park"
                                            image={image3Path}
                                            onClick={() => {
                                                handleOpen(3);
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                padding: "0px !important",
                                                position: "absolute",
                                                bottom: 0,
                                                width: "100%",
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={3}
                                                p={2}
                                                useFlexGap
                                            >
                                                <Stack
                                                    direction="column"
                                                    spacing={0.5}
                                                    useFlexGap
                                                >
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            color: "white",
                                                            textShadow:
                                                                " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;",
                                                        }}
                                                    >
                                                        Camera 3 SW
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={"row"}>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                handleOpen(3);
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            ENLARGE
                                                        </Button>
                                                    </Stack>

                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                getTimeLapsImageArr(
                                                                    image3Path
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            sx={buttonStyle}
                                                            size="small"
                                                        >
                                                            TIME-LAPSE
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                    height={"50vh"}
                                    className="home-grid-item"
                                >
                                    <Card
                                        sx={{
                                            position: "relative",
                                            height: "100%",
                                            maxHeight: "50vh",
                                        }}
                                    >
                                        {imagesTest?.cam4 === false && (
                                            <Badge
                                                color="error"
                                                sx={{
                                                    position: "absolute",
                                                    top: 20,
                                                    left: 60,
                                                    "& .MuiBadge-badge": {
                                                        padding: "0px",
                                                        backgroundColor:
                                                            "transparent",
                                                        boxShadow: "none",
                                                    },
                                                }}
                                                badgeContent={
                                                    <Box
                                                        sx={{
                                                            width: "105px",
                                                            background:
                                                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                                                            color: "white",
                                                            padding: "6px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            borderRadius: "4px",
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                            border: "1px solid red",
                                                        }}
                                                    >
                                                        Not Current Image
                                                    </Box>
                                                }
                                            />
                                        )}
                                        <CardMedia
                                            component="img"
                                            style={{
                                                objectFit: "cover",
                                                objectPosition: "right top",
                                            }}
                                            height="100%"
                                            alt="Yosemite National Park"
                                            image={image4Path}
                                            onClick={() => {
                                                handleOpen(4);
                                            }}
                                        />
                                        <CardContent
                                            sx={{
                                                padding: "0px !important",
                                                position: "absolute",
                                                bottom: 0,
                                                width: "100%",
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={3}
                                                p={2}
                                                useFlexGap
                                            >
                                                <Stack
                                                    direction="column"
                                                    spacing={0.5}
                                                    useFlexGap
                                                >
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontWeight: "bold",
                                                            color: "white",
                                                            textShadow:
                                                                " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;",
                                                        }}
                                                    >
                                                        Camera 4 NW
                                                    </Typography>
                                                </Stack>
                                                <Stack direction={"row"}>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                handleOpen(4);
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            ENLARGE
                                                        </Button>
                                                    </Stack>
                                                    <Stack
                                                        direction="column"
                                                        spacing={0.5}
                                                        marginX={1}
                                                        useFlexGap
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                getTimeLapsImageArr(
                                                                    image4Path
                                                                );
                                                            }}
                                                            variant="outlined"
                                                            size="small"
                                                            sx={buttonStyle}
                                                        >
                                                            TIME-LAPSE
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={4}
                        xs={12}
                        order={{ md: 2, sm: 1, xs: 1 }}
                        sx={{
                            height: { xs: "100%", md: "94vh" },
                            overflowX: "scroll",
                            overflowY: "auto",
                            scrollbarWidth: "none", // Firefox
                            "&::-webkit-scrollbar": {
                                display: "none", // Chrome/Safari
                            },
                        }}
                    >
                        <Grid container spacing={1} padding={2}>
                            {/* <DarkButton /> */}
                            <Grid item xs={12} textAlign={"center"}>
                                <img
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: "120px",
                                        margin: "auto",
                                    }}
                                    src={weatherBrandLogo}
                                    alt=""
                                />
                            </Grid>
                            {!isLoadedData && (
                                <Grid width="100%">
                                    <Stack
                                        direction="row"
                                        width="100%"
                                        height={200}
                                        spacing={10}
                                        alignItems="center"
                                        justifyContent="center"
                                        useFlexGap
                                    >
                                        <CircularProgress />
                                    </Stack>
                                </Grid>
                            )}
                            {isLoadedData && (
                                <>
                                    <Grid item xs={12} marginY={1}>
                                        <Stack
                                            direction="row"
                                            spacing={15}
                                            alignItems="center"
                                            justifyContent="center"
                                            useFlexGap
                                        >
                                            <Stack
                                                direction="column"
                                                useFlexGap
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                    variant="h5"
                                                >
                                                    {String(
                                                        wDaydata.getMonth() + 1
                                                    ).padStart(2, "0")}{" "}
                                                    /{" "}
                                                    {String(
                                                        wDaydata.getDate()
                                                    ).padStart(2, "0")}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="column"
                                                useFlexGap
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                    variant="h5"
                                                >
                                                    {String(
                                                        wDaydata.getHours()
                                                    ).padStart(2, "0")}{" "}
                                                    :{" "}
                                                    {String(
                                                        wDaydata.getMinutes()
                                                    ).padStart(2, "0")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} marginY={1}>
                                        <Stack
                                            direction="row"
                                            spacing={15}
                                            alignItems="center"
                                            justifyContent="center"
                                            useFlexGap
                                        >
                                            <Stack
                                                direction="column"
                                                useFlexGap
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                    variant="h5"
                                                >
                                                    {tempData} &ordm;
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ textAlign: "center" }}
                                                >
                                                    TEMP
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                direction="column"
                                                useFlexGap
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold",
                                                    }}
                                                    variant="h5"
                                                >
                                                    {dewData} &ordm;
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{ textAlign: "center" }}
                                                >
                                                    DEW POINT
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} marginY={1}>
                                        <Stack
                                            direction="column"
                                            spacing={2}
                                            useFlexGap
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                }}
                                                variant="h5"
                                            >
                                                {winddir} &#x2da;@ {windSpeed} G{" "}
                                                {windGuest}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ textAlign: "center" }}
                                            >
                                                WIND (KTS)
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} marginY={1}>
                                        <Stack
                                            direction="column"
                                            spacing={1}
                                            useFlexGap
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                }}
                                                variant="h5"
                                            >
                                                {barometer}{" "}
                                                {isUpBarometer ? `∆` : `∇`}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ textAlign: "center" }}
                                            >
                                                BAROMETER (inHg)
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} marginY={1}>
                                        <Stack
                                            direction="column"
                                            spacing={1}
                                            useFlexGap
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: "center",
                                                    fontWeight: "bold",
                                                }}
                                                variant="h5"
                                            >
                                                {densAltitude?.toFixed(2)}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{ textAlign: "center" }}
                                            >
                                                DENSITY ALTITUDE (FT)
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} sx={{ marginTop: 1 }}>
                                <Stack
                                    direction={"column"}
                                    textAlign={"center"}
                                    spacing={1}
                                    useFlexGap
                                >
                                    <Link href="/wxtrends" underline="always">
                                        <Button
                                            variant="outlined"
                                            style={mainButtonStyle}
                                        >
                                            WEATHER TRENDS
                                        </Button>
                                    </Link>
                                    <Link href="/archive" underline="always">
                                        <Button
                                            variant="outlined"
                                            style={mainButtonStyle}
                                        >
                                            ARCHIVE IMAGES
                                        </Button>
                                    </Link>
                                    <Link href="/#" underline="always">
                                        {isFullscreen ? (
                                            <Button
                                                variant="outlined"
                                                style={mainButtonStyle}
                                                onClick={exitFullscreen}
                                            >
                                                Exit Fullscreen
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                style={mainButtonStyle}
                                                onClick={enterFullscreen}
                                            >
                                                Enter Fullscreen
                                            </Button>
                                        )}
                                    </Link>
                                    {/* <Link to="#" style={{ textDecoration: "none" }}>
                    <Button
                      variant="outlined"
                      style={mainButtonStyle}
                      onClick={handleLogout}
                    >
                      LogOut
                    </Button>
                  </Link> */}
                                    <DarkButton
                                        mainButtonStyle={mainButtonStyle}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Timelapse
                imagesArr={timelapsImgs}
                timelapsOpen={timelapsOpen}
                handleTimelapsClose={handleTimelapsClose}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                playc={playc}
                setPlayc={setPlayc}
            ></Timelapse>
            {/* <Modal
        keepMounted
        open={timelapsOpen}
        onClose={handleTimelapsClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Carousel>
            {timelapsImgs.map((imgItem, key) => (
              <Paper
                key={key}
                sx={{ textAlign: "center", height: "80vh", width: "80vw" }}
              >
                <img
                  style={{
                    height: { md: "100%", xs: "auto" },
                    width: "100%",
                    margin: "auto",
                    // objectFit: "cover"
                  }}
                  src={`${hostPath}${imgItem.path}`}
                  alt=""
                />
                <p className="legend" style={carouselTxt}>
                  {imgItem.modified}
                </p>
              </Paper>
            ))}
          </Carousel>
        </Box>
      </Modal> */}

            <Modal
                keepMounted
                open={enlargeOpen}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={style}>
                    <Box
                        component="img"
                        alt=""
                        src={modalImg}
                        sx={{
                            width: "auto", // Auto width based on image
                            height: "auto",
                            maxWidth: "100%", // Prevents overflow
                            maxHeight: "85vh", // Ensures it stays within the screen
                            objectFit: "contain", // Ensures full image is visible
                            // border: "10px solid white", // Add border around the image
                            // borderRadius: 2, // Smooth edges
                        }}
                    />
                </Box>
            </Modal>
            {/* </Container> */}
        </div>
    );
};
