import React from "react";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils/CookieFunction";
import {
  Link,
  Card,
  CardMedia,
  Stack,
  Typography,
  Container,
  CardContent,
  Paper,
  Badge,
} from "@mui/material";
import { Button } from "@mui/material";

function DarkButton({ mainButtonStyle }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeCookie("token");
    localStorage.removeItem("previousUrl");
    navigate("/login");
  };
  return (
    <Link to="#" style={{ textDecoration: "none" }}>
      <Button variant="outlined" style={mainButtonStyle} onClick={handleLogout}>
        LogOut
      </Button>
    </Link>
  );
}

export default DarkButton;
