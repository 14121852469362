import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useLayoutEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Home } from "./Pages/Home/home";
import { Trends } from "./Pages/Trends/trends";
import { Archive } from "./Pages/Archive/archive";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import Login from "./components/Login";
import UrlTracker, { UrlProvider, useUrlContext } from "./utils/UrlTracker";
import { getCookieValue } from "./utils/CookieFunction";

const theme = createTheme({
  typography: {
    h1: {
      color: "white",
    },
    h2: {
      color: "white",
    },
    h3: {
      color: "white",
    },
    h4: {
      color: "white",
    },
    h5: {
      color: "white",
    },
    body1: {
      color: "black",
    },
    body2: {
      color: "white",
    },
  },
  components: {
    // Name of the component

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: "#fff",
          borderColor: "#fff",
        },
        MuiFocused: {
          borderrColor: "#ff0",
        },
        inputRoot: {
          color: "#fff",
          borderColor: "#fff",
        },
        clearIndicator: {
          color: "white",
        },
        popupIndicator: {
          color: "#fff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#fff",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "white" },
          style: {
            textTransform: "none",
            color: "white",
            width: "100%",
            border: `2px solid #fff`,
          },
        },
        {
          props: { variant: "dashed", color: "secondary" },
          style: {
            border: `4px dashed #f00`,
          },
        },
      ],
    },
  },
});

function App() {
  return (
    // <ThemeProvider theme={theme}>
    //   <BrowserRouter basename="/">
    //     <Routes>
    //       <Route element={<LoginConformation />}>
    //         <Route path="/login" element={<Login />} />
    //       </Route>
    //       <Route element={<PrivateRoute />}>
    //         <Route path="/" element={<Home />} />
    //         <Route path="/wxtrends" element={<Trends />} />
    //         <Route path="/archive" element={<Archive />} />
    //       </Route>
    //     </Routes>
    //   </BrowserRouter>
    // </ThemeProvider>
    <UrlTracker>

  
      <ThemeProvider theme={theme}>
        <BrowserRouter basename="/">
          <Routes>
            <Route element={<LoginConformation />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/wxtrends" element={<Trends />} />
              <Route path="/archive" element={<Archive />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      </UrlTracker>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}


const LoginConformation = () => {
  const navigate = useNavigate();
  const token = getCookieValue("token")

  React.useEffect(() => {
    if (token) {
      const previousUrl = localStorage.getItem("previousUrl");

      if (previousUrl) {
        navigate(previousUrl);
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  return !token ? <Outlet /> : null;
};
const PrivateRoute = () => {
  const token = getCookieValue("token")
  return token ? <Outlet /> :<Navigate to={"/login"}/>
};

export default App;
