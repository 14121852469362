
import { useEffect } from "react";

const UrlTracker = ({ children }) => {
  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath==="/login") {
      return
      
    }

    localStorage.setItem("previousUrl", currentPath);

    const handlePopState = () => {
      const currentPath = window.location.pathname;
      localStorage.setItem("previousUrl", currentPath);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return children;
};

export default UrlTracker;
