import React from "react";

function CommonSelecter({
  name,
  number,
  setTimerData,
  toggleHook,
  setNumberMonth,
  numberMonth,
}) {
  const setNumMonthVal = (monthVal) => {
    if (numberMonth) {
      setNumberMonth(monthVal);
    }
  };

  const setAllData = () => {
    if (name && number) {
      setTimerData(`${name} - ${number}`);
    } else {
      setTimerData(name);
    }
  }; 
  return (
    <>
      <div
        className="hour-box"
        onClick={() => {
          
          setAllData()
          setNumMonthVal(number);
          toggleHook(false);
        }}
      >
        {name}
      </div>
    </>
  );
}

export default CommonSelecter;
