import axios from "axios";
import { setCookie } from "../utils/CookieFunction";

const apiProd = "https://denalicams.com";
const apiLocal = "http://localhost:4000";
const realPath = apiProd;

export const loginUser = async (email, password) => {
  let loginResponse = null;
  try {
    const response = await axios({
      method: "post",
      url: `${apiProd}/auth/login`,
      data: { email, password },
    });
    const token = response.data.token;
    setCookie("token", token, 3600*24*30);

    loginResponse = response.data;
  } catch (error) {
    loginResponse = null;
    console.error("Error during login:", error.response?.data || error.message);
  }
  return loginResponse;
};

export const getCamFourFiles = async (camStr) => {
  let camForFiles = null;
  await axios({
    method: "post",
    url: `${realPath}/wximgs/readCamImgs`,
    data: { camStr },
  })
    .then(function (response) {
      camForFiles = response.data;
      // console.log(camForFiles);
    })
    .catch(function (error) {
      camForFiles = null;
      console.log("errors", error);
    });

  return camForFiles;
};

export const getTimeLapsImgs = async (path) => {
  let weatherData = null;
  await axios({
    method: "post",
    url: `${realPath}/wximgs/getTimelapsImgs`,
    data: { path },
  })
    .then(function (response) {
      weatherData = response.data;
    })
    .catch(function (error) {
      weatherData = null;
      console.log("errors", error);
    });

  return weatherData;
};

export const getReadImgfile = async (path, isLoading, setIsLoading) => {
  let weatherData = null;
  setIsLoading(true);
  await axios({
    method: "post",
    url: `${realPath}/wximgs/readImgfile`,
    data: { path },
  })
    .then(function (response) {
      weatherData = response.data;
    })
    .catch(function (error) {
      weatherData = null;
      console.log("errors", error);
    });
  setIsLoading(false);
  return weatherData;
};

export const getWeatherData = async () => {
  let weatherData = null;
  await axios({
    method: "post",
    url: `${realPath}/wximgs/readWeatherData`,
    data: {},
  })
    .then(function (response) {
      weatherData = response.data;
    })
    .catch(function (error) {
      weatherData = null;
      console.log("errors", error);
    });

  return weatherData;
};

export const getWeatherTrends = async () => {
  let trendsData = [];
  await axios({
    method: "post",
    url: `${realPath}/wximgs/readWeadtheTrends`,
    data: {},
  })
    .then(function (response) {
      trendsData = response.data;
    })
    .catch(function (error) {
      trendsData = [];
      console.log("errors", error);
    });

  return trendsData;
};

export const getYearData = async () => {
  let yearlist = [];
  await axios({
    method: "get",
    url: `${realPath}/wximgs/getyearlist`,
  })
    .then(function (response) {
      yearlist = response.data;
    })
    .catch(function (error) {
      yearlist = [];
      console.log("errors", error);
    });
  return yearlist;
};
