import React from "react";

const DayPicker = ({ month, year, onSelectDay }) => {
 
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(month, year);

  const handleDayClick = (day) => {
    if (onSelectDay) {
      onSelectDay(day);
    }
  };

  return (
    <div className="day-picker">
      <h3>
        Select a Day for{" "}
        {new Date(year, month).toLocaleString("default", { month: "long" })},{" "}
        {year}
      </h3>
      <div >
        {Array.from({ length: daysInMonth }, (_, index) => (
          <button
            key={index + 1}
            className="date-picker-bg"
            onClick={() => handleDayClick(index + 1)}
           
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

const SelectedDayPicker = ({ numberMonth, setTdate, setToggleDay ,setTyear}) => {
  const handleDaySelect = (day) => {
    setTdate(day);
    setToggleDay(false);
  };

  return (
    <DayPicker
      month={numberMonth - 1}
      year={setTyear}
      onSelectDay={handleDaySelect}
    />
  ); 
};

export default SelectedDayPicker;
