import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import weatherBrand from "../assets/weatherBrandLogo.png";
// import axios from "axios";
import { loginUser } from "../apis/apiProvider";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  // Validation logic
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    const response = await loginUser(email, password);

    if (response) {
      navigate("/");
    } else {
      setError("Invalid credentials or login failed.");
    }
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // Background color for the container
      }}
    >
      <Box
        sx={{
          width: "80%",
          maxWidth: 400,
          padding: 3,
          borderRadius: 2,
          boxShadow: 6,
          backgroundColor: "#282C34",
        }}
      >
        {/* <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 3 }}>
          Login
        </Typography> */}
        <Grid item xs={12} textAlign={"center"}>
          <img
            style={{ maxWidth: "100%", maxHeight: "120px", margin: "auto" }}
            src={weatherBrand}
            alt=""
          />
        </Grid>
        <form onSubmit={handleSubmit}>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          {/* Email Field */}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px", // Set the input height
                "& fieldset": {
                  borderColor: "gray", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "gray", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gray", // Focused border color
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray", // Default label color
                fontSize: "16px", // Set label font size
                textAlign: "center", // Align label text
                top: "50%", // Vertically center the label
                left: "13px", // Align with padding
                transform: "translateY(-50%)", // Center vertically
                transformOrigin: "left", // Anchor point for animation
                transition: "all 0.3s ease-in-out", // Add smooth animation
              },
              "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled":
                {
                  color: "white", // Focused label color
                  fontSize: "12px", // Smaller size when floating
                  top: "-9px", // Move above the input
                  transform: "translateY(0)", // Align at the top
                },
              "& input": {
                color: "white", // Input text color
                padding: "10px", // Adjust padding for content
                backgroundColor: "#282c34", // Input background color
                caretColor: "white", // Caret (cursor) color
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0px 1000px #282c34 inset !important", // Autofill background color
                WebkitTextFillColor: "white !important", // Autofill text color
              },
            }}
          />

          {/* Password Field */}
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            // required
            sx={{
              "& .MuiOutlinedInput-root": {
                height: "40px", // Set the input height
                "& fieldset": {
                  borderColor: "gray", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "gray", // Hover border color
                },
                "&.Mui-focused fieldset": {
                  borderColor: "gray", // Focused border color
                },
              },
              "& .MuiInputLabel-root": {
                color: "gray", // Default label color
                fontSize: "16px", // Set label font size
                textAlign: "center", // Align label text
                top: "50%", // Vertically center the label
                left: "13px", // Align with padding
                transform: "translateY(-50%)", // Center vertically
                transformOrigin: "left", // Anchor point for animation
                transition: "all 0.3s ease-in-out", // Add smooth animation
              },
              "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled":
                {
                  color: "white", // Focused label color
                  fontSize: "12px", // Smaller size when floating
                  top: "-8px", // Move above the input
                  transform: "translateY(0)", // Align at the top
                },
              "& input": {
                color: "white", // Input text color
                padding: "10px", // Adjust padding for content
                backgroundColor: "#282c34", // Input background color
                caretColor: "white", // Caret (cursor) color
              },
              "& input:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0px 1000px #282c34 inset !important", // Autofill background color
                WebkitTextFillColor: "white !important", // Autofill text color
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
          >
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
