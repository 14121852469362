import * as React from "react";
import {
  Link,
  Card,
  CardMedia,
  Box,
  Container,
  Grid,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CardContent,
  ToggleButton,
  Dialog,
  Badge,
} from "@mui/material";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { LineChart } from "@mui/x-charts/LineChart";
import { mangoFusionPalette } from "@mui/x-charts/colorPalettes";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import testImg from "../../assets/data/cam1,m240408180000864.jpg";
import { DayPicker } from "react-day-picker";
import { format } from "date-fns";
import emptyImg from "../../assets/empty.png";
import Modal from "@mui/material/Modal";

import {
  getCamFourFiles,
  getReadImgfile,
  getYearData,
} from "../../apis/apiProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import CommonSelecter from "../../components/common-component/CommonSelecter";
import DateTimeInputs from "../../components/common-component/CommonDayPicker";
import MonthPickerComponent from "../../components/common-component/CommonDayPicker";
import SelectedDayPicker from "../../components/common-component/CommonDayPicker";
import DarkButton from "../../components/common-component/DarkButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: { xs: "95%", sm: "max-content" },
  maxWidth: "100vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: {
    md: "20px solid #fff",
    xs: "10px solid #fff",
    lg: "25px solid #fff",
  },
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: "hidden",
};
const mainButtonStyle = {
  color: "white",
  textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};
const commonButtonStyle = {
  marginTop: "8px",
  width: "100%",
  color: "white",
  textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};
const textStyle = {
  textAlign: "center",
  fontWeight: "bold",
  color: "white",
  textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};
const mediaContentStyle = {
  padding: "0px !important",
  position: "absolute",
  bottom: 0,
  width: "100%",
};
const hostPath = "https://denalicams.com";

export const Archive = () => {
  const [enlargeOpen, setEnlargeOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const defaultYear = ["2024", "2025"];
  const [yearlist, setYearlist] = React.useState(defaultYear);
  const tdayData = new Date();
  const [tdate, setTdate] = React.useState(tdayData.getDate());
  const [tyear, setTyear] = React.useState(tdayData.getFullYear());
  const [numberMonth, setNumberMonth] = React.useState(tdayData.getMonth() + 1);
  const [tmonth, setTmonth] = React.useState(numberMonth);
  const [thours, setThours] = React.useState(tdayData.getHours());
  const [tmins, setTmins] = React.useState();
  const [cam1Img, setCam1Img] = React.useState(emptyImg);
  const [cam2Img, setCam2Img] = React.useState(emptyImg);
  const [cam3Img, setCam3Img] = React.useState(emptyImg);
  const [cam4Img, setCam4Img] = React.useState(emptyImg);
  const [modalImg, setModalImg] = React.useState(emptyImg);
  const [toggleHover, setToggleHover] = React.useState(false);
  const [toggleMin, setToggleMin] = React.useState(false);
  const [toggleDay, setToggleDay] = React.useState(false);
  const [toggleYear, setToggleYear] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [imagesTest, setImagesTest] = React.useState(null);
  const [disabledButton, setDisabledButton] = React.useState(null);

  const monthlist = [
    { name: "JAN", number: 1 },
    { name: "FEB", number: 2 },
    { name: "MAR", number: 3 },
    { name: "APR", number: 4 },
    { name: "MAY", number: 5 },
    { name: "JUN", number: 6 },
    { name: "JUL", number: 7 },
    { name: "AUG", number: 8 },
    { name: "SEP", number: 9 },
    { name: "OCT", number: 10 },
    { name: "NOV", number: 11 },
    { name: "DEC", number: 12 },
  ];
  let daylist = [];
  let hrList = [];
  let minList = [];
  for (let index = 1; index < 32; index++) {
    daylist.push(index);
  }
  for (let hNum = 0; hNum < 24; hNum++) {
    hrList.push(hNum);
  }
  for (let mNum = 0; mNum < 60; mNum += 5) {
    minList.push(`${mNum}-${mNum + 5}`);
  }

  const realMin = tdayData.getMinutes();
  const getCurrentMinuteRange = () => {
    for (let i = 0; i < minList.length; i++) {
      const range = minList[i];
      const [start, end] = range.split("-").map(Number);
      if (realMin >= start && realMin < end) {
        return range;
      }
    }
    return ""; // Default case if no match
  };
  React.useEffect(() => {
    setTmins(getCurrentMinuteRange());
  }, []);



  // console.log(minList);
  const handleClose = () => {
    setEnlargeOpen(false);
  };
  const handleOpen = (num) => {
    switch (num) {
      case 1:
        setModalImg(cam1Img);
        break;
      case 2:
        setModalImg(cam2Img);
        break;
      case 3:
        setModalImg(cam3Img);
        break;
      case 4:
        setModalImg(cam4Img);
        break;
      default:
        break;
    }
    setEnlargeOpen(true);
  };

  React.useEffect(() => {
    initLoad();
  }, []);

  React.useEffect(() => {
    handleYearData();
  }, []);
  const handleYearData = async () => {
    const yeardata = await getYearData();
    if (yeardata?.length) {
      setYearlist(yeardata);
    }
  };

  // const onYearChange = (e) => {
  //   console.log(e);
  //   setTyear(e.target.value);
  // };
  // const handleMonthChange = (month) => {
  //   setTmonth(month.number); // Set the selected month's number
  //   handleClose(); // Close modal after selection
  // };
  // const onDayChange = (e) => {
  //   setTdate(e.target.value);
  // };
  // const onHourChange = (e) => {
  //   setThours(e.target.value);
  // };
  // const onMinsChange = (e) => {
  //   setTmins(e.target.value);
  // };
  const initLoad = async () => {
    const imagesData = await getCamFourFiles("cam4");
    if (imagesData?.resultDataLive) {
      setImagesTest(imagesData.resultDataLive);
      setDisabledButton(imagesData);
    }
    if (!imagesData) return;
    // setImagePath(`${hostPath}${imagesData.path}/${imagesData.imagelist[0].name}`);
    if (imagesData.resultData.cam1) {
      setCam1Img(`${hostPath}${imagesData.resultData.cam1}`);
    }
    if (imagesData.resultData.cam2) {
      setCam2Img(`${hostPath}${imagesData.resultData.cam2}`);
    }
    if (imagesData.resultData.cam3) {
      setCam3Img(`${hostPath}${imagesData.resultData.cam3}`);
    }
    if (imagesData.resultData.cam4) {
      setCam4Img(`${hostPath}${imagesData.resultData.cam4}`);
    }
  };
  // console.log("numberMonth", thours);
  const loadImages = async () => {
    // const loadImgPath = `${hostPath}${tyear.toString().padStart(2, '0')}/${tmonth.toString().padStart(2, '0')}/${tdate.toString().padStart(2, '0')}/${thours.toString().padStart(2, '0')}/cam4-m`
    // setImagesTest(null);

    const [start, end] = tmins.split("-");
    // console.log(start, end);
    const loadImgPath = `${tyear.toString().padStart(2, "0")}/${numberMonth
      .toString()
      .padStart(2, "0")}/${tdate.toString().padStart(2, "0")}/${thours
      .toString()
      .padStart(2, "0")}/${start.toString().padStart(2, "0")}/${end
      .toString()
      .padStart(2, "0")}`;
    // console.log(loadImgPath);
    const imagesArr = await getReadImgfile(
      loadImgPath,
      isLoading,
      setIsLoading
    );
    setImagesTest(imagesArr.listDataTime);

    if (imagesArr) {
      if (imagesArr.listData.cam1) {
        const cam1Img = imagesArr.listData.cam1;
        const cam1Path = `${hostPath}/${cam1Img}`;
        setCam1Img(cam1Path);
      }
      if (imagesArr.listData.cam2) {
        const cam2Img = imagesArr.listData.cam2;
        const cam2Path = `${hostPath}/${cam2Img}`;
        setCam2Img(cam2Path);
      }
      if (imagesArr.listData.cam3) {
        const cam3Img = imagesArr.listData.cam3;
        const cam3Path = `${hostPath}/${cam3Img}`;
        setCam3Img(cam3Path);
      }
      if (imagesArr.listData.cam4) {
        const cam4Img = imagesArr.listData.cam4;
        const cam4Path = `${hostPath}/${cam4Img}`;
        setCam4Img(cam4Path);
      }
      // const loadingPath = `${hostPath}${tyear.toString().padStart(2, '0')}/${tmonth.toString().padStart(2, '0')}/${tdate.toString().padStart(2, '0')}/${thours.toString().padStart(2, '0')}/${imagesArr[0].name}`
      // setLoaderPath(loadingPath);
    } else {
      alert("There is not the image");
    }
  };

  // Out Side Toggle Functionality

  const yearRef = React.useRef(null);
  const monthRef = React.useRef(null);
  const dayRef = React.useRef(null);
  const hoverRef = React.useRef(null);
  const minRef = React.useRef(null);

  // Handle outside click for all selectors
  const handleClickOutside = (event) => {
    if (yearRef.current && !yearRef.current.contains(event.target)) {
      setToggleYear(false);
    }
    if (monthRef.current && !monthRef.current.contains(event.target)) {
      setOpen(false);
    }
    if (dayRef.current && !dayRef.current.contains(event.target)) {
      setToggleDay(false);
    }
    if (hoverRef.current && !hoverRef.current.contains(event.target)) {
      setToggleHover(false);
    }
    if (minRef.current && !minRef.current.contains(event.target)) {
      setToggleMin(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const adjustDropdownHeight = (containerRef, isOpen) => {
    if (!containerRef?.current || !isOpen) return;

    const toggleElement = containerRef.current.querySelector('.input-tag');
    const dropdownElement = containerRef.current.querySelector('.hover-container') ||
      containerRef.current.querySelector('.child-container') || containerRef.current.querySelector('.day-picker-container');

    if (toggleElement && dropdownElement) {
      const toggleRect = toggleElement.getBoundingClientRect();
      const toggleBottom = toggleRect.bottom;
      const pageHeight = window.innerHeight;

      // Calculate available space
      const availableHeight = pageHeight - toggleBottom;

      // Set the appropriate height and overflow
      if (availableHeight < 5000) {
        dropdownElement.style.maxHeight = `${availableHeight - 50}px`;
        dropdownElement.style.overflowY = 'auto';
      } else {
        dropdownElement.style.maxHeight = 'fit-content';
        dropdownElement.style.overflowY = 'visible';
      }
    }
  };

  // Create a unified resize handler
  const useDropdownResize = (refs, states) => {
    React.useEffect(() => {
      const handleResize = () => {
        refs.forEach((ref, index) => {
          if (states[index]) {
            adjustDropdownHeight(ref, states[index]);
          }
        });
      };

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [...states]); // Dependencies are all toggle states
  };

  // Usage in your component:
  // First, set up the resize effect with all refs and states
  useDropdownResize(
    [hoverRef, monthRef, dayRef, minRef],
    [toggleHover, open, toggleDay, toggleMin]
  );

  React.useEffect(() => {
    const handleResize = () => {
      if (toggleHover) {
        adjustDropdownHeight();
      }
    };
    window.addEventListener('resize', handleResize);
    console.log('errororrr', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggleHover]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <Box
        sx={{ padding: "0.8rem",paddingBottom:0, height: "98vh" }}
        className="home-container"
      >
        <Grid container height={"100%"}>
          <Grid
            item
            md={8}
            xs={12}
            order={{ md: 1, sm: 2, xs: 2 }}
            height={"98vh"}
            className="home-grid"
            overflow-y={"hidden"}
          >
            <Box height={{sm:"auto",md:"100vh"}}>
              <Grid container spacing={2} height={"100%"}>
                <Grid
                  item
                  md={6}
                  xs={12}
                  maxHeight={"50vh"}
                  className="home-grid-item"
                >
                  <Card
                    sx={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "50vh",
                    }}
                  >
                    {imagesTest?.cam1 === false && (
                      <Badge
                        color="error"
                        sx={{
                          position: "absolute",
                          top: 20,
                          left: 60,
                          "& .MuiBadge-badge": {
                            padding: "0px",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        badgeContent={
                          <Box
                            sx={{
                              width: "105px",
                              background:
                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                              color: "white",
                              padding: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: "1px solid red",
                            }}
                          >
                            Not Expected Image
                          </Box>
                        }
                      />
                    )}

                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "cover",
                        objectPosition: "right top",
                      }}
                      height="100%"
                      alt="Yosemite National Park"
                      image={cam1Img}
                      onClick={() => {
                        handleOpen(1);
                      }}
                    />
                    <CardContent sx={mediaContentStyle}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                        p={2}
                        useFlexGap
                      >
                        <Stack direction="column" spacing={0.5} useFlexGap>
                          <Typography sx={textStyle}>Camera 1 NW</Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  maxHeight={"50vh"}
                  className="home-grid-item"
                >
                  <Card
                    sx={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "50vh",
                    }}
                  >
                    {imagesTest?.cam2 === false && (
                      <Badge
                        color="error"
                        sx={{
                          position: "absolute",
                          top: 20,
                          left: 60,
                          "& .MuiBadge-badge": {
                            padding: "0px",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        badgeContent={
                          <Box
                            sx={{
                              width: "105px",
                              background:
                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                              color: "white",
                              padding: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: "1px solid red",
                            }}
                          >
                            Not Expected Image
                          </Box>
                        }
                      />
                    )}
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "cover",
                        objectPosition: "right top",
                      }}
                      height="100%"
                      alt="Yosemite National Park"
                      image={cam2Img}
                      onClick={() => {
                        handleOpen(2);
                      }}
                    />
                    <CardContent sx={mediaContentStyle}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                        p={2}
                        useFlexGap
                      >
                        <Stack direction="column" spacing={0.5} useFlexGap>
                          <Typography sx={textStyle}>Camera 2 NE</Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  maxHeight={"50vh"}
                  className="home-grid-item"
                >
                  <Card
                    sx={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "50vh",
                    }}
                  >
                    {imagesTest?.cam3 === false && (
                      <Badge
                        color="error"
                        sx={{
                          position: "absolute",
                          top: 20,
                          left: 60,
                          "& .MuiBadge-badge": {
                            padding: "0px",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        badgeContent={
                          <Box
                            sx={{
                              width: "105px",
                              background:
                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                              color: "white",
                              padding: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: "1px solid red",
                            }}
                          >
                            Not Expected Image
                          </Box>
                        }
                      />
                    )}
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "cover",
                        objectPosition: "right top",
                      }}
                      height="100%"
                      alt="Yosemite National Park"
                      image={cam3Img}
                      onClick={() => {
                        handleOpen(3);
                      }}
                    />
                    <CardContent sx={mediaContentStyle}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                        p={2}
                        useFlexGap
                      >
                        <Stack direction="column" spacing={0.5} useFlexGap>
                          <Typography sx={textStyle}>Camera 3 SE</Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                  maxHeight={"50vh"}
                  className="home-grid-item"
                >
                  <Card
                    sx={{
                      position: "relative",
                      height: "100%",
                      maxHeight: "50vh",
                    }}
                  >
                    {imagesTest?.cam4 === false && (
                      <Badge
                        color="error"
                        sx={{
                          position: "absolute",
                          top: 20,
                          left: 60,
                          "& .MuiBadge-badge": {
                            padding: "0px",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                          },
                        }}
                        badgeContent={
                          <Box
                            sx={{
                              width: "105px",
                              background:
                                " linear-gradient(to top, #ff0000 20%,rgb(245, 165, 165) 100%);",
                              color: "white",
                              padding: "6px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: "4px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              border: "1px solid red",
                            }}
                          >
                            Not Expected Image
                          </Box>
                        }
                      />
                    )}
                    <CardMedia
                      component="img"
                      style={{
                        objectFit: "cover",
                        objectPosition: "right top",
                      }}
                      height="100%"
                      alt="Yosemite National Park"
                      image={cam4Img}
                      onClick={() => {
                        handleOpen(4);
                      }}
                    />
                    <CardContent sx={mediaContentStyle}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={3}
                        p={2}
                        useFlexGap
                      >
                        <Stack direction="column" spacing={0.5} useFlexGap>
                          <Typography sx={textStyle}>Camera 4 SW</Typography>
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={4} xs={12} order={{ md: 2, xs: 1 }}>
            <Box>
              <Container>
                <Typography
                  sx={{ fontWeight: "bold", marginY: 2, textAlign: "center" }}
                  variant="h4"
                >
                  ARCHIVED IMAGES
                </Typography>
                <Box>
                  <Grid container spacing={1}>
                    {/* <Grid item md={12} xs={4} order={{ md: 1, xs: 3 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Year
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tyear}
                          sx={{ color: "white" }}
                          label="Year"
                          onChange={(e) => {
                            onYearChange(e);
                          }}
                        >
                          {yearlist?.map((yearItem) => (
                            <MenuItem key={yearItem} value={yearItem}>
                              {yearItem}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                    {/* <Grid item md={12} xs={4} order={{ md: 2, xs: 4 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Month
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tmonth}
                          sx={{ color: "white" }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200, // Adjust the maximum height here
                              },
                            },
                          }}
                          label="Month"
                          onChange={(e) => {
                            onMonthChange(e);
                          }}
                        >
                          {monthlist.map((monthItem) => (
                            <MenuItem key={monthItem} value={monthItem}>
                              {monthItem.name} {monthItem.number}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                    {/* YEARS - SELECTER */}
                    <div className="main-component" ref={yearRef}>
                      <div className="text-design">Year</div>
                      <div
                        className="input-tag"
                        onClick={() => setToggleYear(!toggleYear)}
                      >
                        <div className="p-text">{tyear}</div>
                      </div>
                      <div className="m-component">
                        {toggleYear && (
                          <div className="flex-common-class">
                            {yearlist?.map((YearData) => (
                              <div
                                className="month-box"
                                onClick={() => {
                                  setTyear(YearData);
                                  setToggleYear(false);
                                }}
                              >
                                {YearData}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Month - SELECTER */}
                    <div
                      className="main-component"
                      style={{ marginTop: "20px" }}
                      ref={monthRef}
                    >
                      <div className="text-design">Month</div>
                      <div
                        className="input-tag"
                        onClick={() => {
                          setOpen(!open);
                          setTimeout(() => {
                            if (!open) adjustDropdownHeight(monthRef, true);
                          }, 0);
                        }}
                      >
                        <div className="p-text">{tmonth}</div>
                      </div>
                      <div className="m-component">
                        {open && (
                          <div className="child-container" width="100%">
                            {monthlist.map((monthData) => (
                              <CommonSelecter
                                name={monthData.name}
                                number={monthData.number}
                                setTimerData={setTmonth}
                                toggleHook={setOpen}
                                setNumberMonth={setNumberMonth}
                                numberMonth={numberMonth}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* DAY - SELECTER */}
                    <div
                      className="main-component"
                      style={{ marginTop: "20px" }}
                      ref={dayRef}
                    >
                      <div className="text-design">Day</div>
                      <div
                        className="input-tag"
                        onClick={() => {
                          setToggleDay(!toggleDay);
                          setTimeout(() => {
                            if (!toggleDay) adjustDropdownHeight(dayRef, true);
                          }, 0);
                        }}
                      >
                        <div className="p-text">{tdate}</div>
                      </div>
                      <div className="m-component">

                        {toggleDay && (
                          <div className="day-picker-container">
                          <SelectedDayPicker
                            setToggleDay={setToggleDay}
                            numberMonth={numberMonth}
                            setTdate={setTdate}
                            setTyear={tyear}
                          />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* HOUR - SELECTER */}
                    <div
                      className="main-component"
                      style={{ marginTop: "50px" }}
                      ref={hoverRef}
                    >
                      <div className="text-design">Hour</div>
                      <div
                        className="input-tag"
                        onClick={() => {
                          setToggleHover(!toggleHover);
                          // Add setTimeout to ensure the dropdown is rendered before measuring
                          setTimeout(() => {
                            if (!toggleHover) adjustDropdownHeight(hoverRef, true);
                          }, 0);
                        }}
                      >
                        <div className="p-text">{thours}</div>
                      </div>
                      <div className="m-component">
                        {toggleHover && (
                          <div className="hover-container" style={{ maxHeight: 'fit-content', overflowY: 'visible' }}>
                            {hrList.map((monthData) => (
                              <CommonSelecter
                                key={monthData}
                                name={monthData}
                                setTimerData={setThours}
                                toggleHook={setToggleHover}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* MINUTE - SELECTER */}
                    <div
                      className="main-component"
                      style={{ marginTop: "20px" }}
                      ref={minRef}
                    >
                      <div className="text-design">Minute</div>
                      <div
                        className="input-tag"
                        onClick={() => {
                          setToggleMin(!toggleMin);
                          setTimeout(() => {
                            if (!toggleMin) adjustDropdownHeight(minRef, true);
                          }, 0);
                        }}
                      >
                        <div className="p-text">{tmins}</div>
                      </div>
                      <div className="m-component">
                        {toggleMin && (
                          <div className="child-container">
                            {minList.map((monthData) => (
                              <CommonSelecter
                                name={monthData}
                                setTimerData={setTmins}
                                toggleHook={setToggleMin}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <Grid item md={12} xs={4} order={{ md: 3, xs: 5 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Day
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tdate}
                          label="Day"
                          sx={{ color: "white" }}
                          onChange={(e) => {
                            onDayChange(e);
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200, // Adjust the maximum height here
                              },
                            },
                          }}
                        >
                          {daylist.map((datyItem) => (
                            <MenuItem key={datyItem} value={datyItem}>
                              {datyItem}
                            </MenuItem>
                          ))}
                           <DayPicker
                            selected={selectedDate}
                            onDayClick={handleDateChange} // Updates selected date on click
                            mode="single" // Allows selecting one date at a time
                            todayButton="Go to Today" // Optional 'Go to Today' button
                            month={
                              selectedDate ? new Date(selectedDate) : undefined
                            } // Display current month based on selected date
                          /> 
                        </Select>
                      </FormControl>
                    </Grid>  */}
                    {/* <Grid item xs={12} order={{ md: 4, xs: 8 }}>
                      <span
                        style={{ paddingLeft: "30px", paddingRight: "30px" }}
                      ></span>
                    </Grid> */}
                    {/* <Grid item md={12} xs={6} order={{ md: 5, xs: 6 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Hour
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={thours}
                          sx={{ color: "white" }}
                          label="Hour"
                          onChange={(e) => {
                            onHourChange(e);
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200, // Adjust the maximum height here
                              },
                            },
                          }}
                        >
                          {hrList.map((hrItem) => (
                            <MenuItem key={hrItem} value={hrItem}>
                              {hrItem}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                    {/* <Grid item md={12} xs={6} order={{ md: 6, xs: 7 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Min
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tmins}
                          sx={{ color: "white" }}
                          label="Min"
                          onChange={(e) => {
                            onMinsChange(e);
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: 200, // Adjust the maximum height here
                              },
                            },
                          }}
                        >
                          {minList.map((minItem) => (
                            <MenuItem key={minItem} value={minItem}>
                              {minItem}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid
                      container
                      spacing={1}
                      item
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                      md={12}
                      order={{ md: 3, xs: 3 }}
                      paddingBottom={"1rem"}
                    >
                      <Grid
                        item
                        sx={{
                          display: { xs: "block", md: "none" },
                        }}
                        md={12}
                        order={{ md: 7, xs: 2 }}
                      >
                        <LoadingButton
                          loading={isLoading}
                          variant="contained"
                          loadingPosition="center"
                          onClick={() => loadImages()}
                          disabled={!disabledButton || isLoading}
                          sx={{
                            ...mainButtonStyle,
                            backgroundColor: "#1976D2",
                            color: "#fff",
                            minWidth: "150px",
                            minHeight: "40px",
                            "&:hover": {
                              backgroundColor: "#115293",
                            },
                            "&.Mui-disabled": {
                              backgroundColor: "#1976D2 !important",
                              color: "#fff !important",
                              cursor: "progress !important",
                              // pointerEvents: "none",
                              opacity: 0.7,
                            },
                            "& .MuiLoadingButton-loadingIndicator": {
                              color: "#fff",
                            },
                            "&.MuiLoadingButton-loading": {
                              backgroundColor: "#1976D2",
                            },
                          }}
                          fullWidth
                        >
                          {!isLoading && "Load"}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: { xs: "none", md: "block" },
                      }}
                      md={12}
                      order={{ md: 7, xs: 2 }}
                    >
                      <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        loadingPosition="center"
                        onClick={() => loadImages()}
                        disabled={!disabledButton || isLoading}
                        sx={{
                          ...mainButtonStyle,
                          backgroundColor: "#1976D2",
                          color: "#fff",
                          minWidth: "150px",
                          minHeight: "40px",
                          "&:hover": {
                            backgroundColor: "#115293",
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#1976D2 !important",
                            color: "#fff !important",
                            cursor: "progress !important",
                            // pointerEvents: "none",
                            opacity: 0.7,
                          },
                          "& .MuiLoadingButton-loadingIndicator": {
                            color: "#fff",
                          },
                          "&.MuiLoadingButton-loading": {
                            backgroundColor: "#1976D2",
                          },
                        }}
                        fullWidth
                      >
                        {!isLoading && "Load"}
                      </LoadingButton>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: { xs: "none", md: "block" },
                      }}
                      md={12}
                      order={{ md: 7, xs: 2 }}
                    >
                      <Link href="/" sx={{ width: "100%" }}>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            loadImages();
                          }}
                          sx={mainButtonStyle}
                          fullWidth
                        >
                          <ChevronLeftIcon /> Back To Home
                        </Button>
                      </Link>

                      <Grid
                        item
                        sx={{
                          display: { xs: "none", md: "block" },
                        }}
                        md={12}
                        order={{ md: 7, xs: 2 }}
                      >
                        <DarkButton mainButtonStyle={commonButtonStyle} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Box>
          </Grid>
          <Grid
            container
            spacing={1}
            item
            sx={{
              display: { xs: "block", md: "none" },
              paddingTop: "1rem",
            }}
            md={12}
            order={{ md: 3, xs: 3 }}
            paddingBottom={"1rem"}
          >
            <Grid
              item
              sx={{
                display: { xs: "block", md: "none" },
              }}
              md={12}
              order={{ md: 7, xs: 2 }}
            >
              <Link href="/" sx={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    loadImages();
                  }}
                  sx={mainButtonStyle}
                  fullWidth
                >
                  <ChevronLeftIcon /> Back To Home
                </Button>
              </Link>

              <Grid
                item
                sx={{
                  display: { xs: "block", md: "none" },
                }}
                md={12}
              >
                <DarkButton mainButtonStyle={commonButtonStyle} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Modal
        keepMounted
        open={enlargeOpen}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box
            component="img"
            alt=""
            src={modalImg}
            sx={{
              width: "auto",
              height: "auto",
              maxWidth: "100%",
              maxHeight: "85vh",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};
