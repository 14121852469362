export const setCookie = (cookieName, cookieValue, maxAgeInSeconds) => {
    const d = new Date();
    d.setTime(d.getTime() + maxAgeInSeconds * 1000); // Convert maxAge to milliseconds
    const expirationDate = d.toUTCString();
    const cookieString = `${cookieName}=${cookieValue}; expires=${expirationDate}; path=/`;
    document.cookie = cookieString;
  };
  
  export const removeCookie = (cookieName) => {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() - 1);
    const cookieOptions = `expires=${expirationDate.toUTCString()}; path=/`;
    document.cookie = `${cookieName}=; ${cookieOptions}`;
  };
  
  export const getCookieValue = (cookieName) => {
    const cookies = document?.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + "=")) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null; // Return null if the cookie is not found
  };
  