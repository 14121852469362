
import { useState, useEffect } from 'react';

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const enterFullscreen = () => {
    const element = document.documentElement;
    const requestFn = element.requestFullscreen || 
      element.mozRequestFullScreen || 
      element.webkitRequestFullscreen || 
      element.msRequestFullscreen;
    
    if (requestFn) {
      requestFn.call(element);
    }
  };

  const exitFullscreen = () => {
    const exitFn = document.exitFullscreen || 
      document.mozCancelFullScreen || 
      document.webkitExitFullscreen || 
      document.msExitFullscreen;
    
    if (exitFn) {
      exitFn.call(document);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return { isFullscreen, enterFullscreen, exitFullscreen };
};