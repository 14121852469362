import * as React from "react";
import {
  Link,
  Box,
  Container,
  Grid,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { LineChart } from "@mui/x-charts/LineChart";
import { mangoFusionPalette } from "@mui/x-charts/colorPalettes";
import { getWeatherTrends } from "../../apis/apiProvider";
import CircularProgress from "@mui/material/CircularProgress";
import DarkButton from "../../components/common-component/DarkButton";

const defaultSeries = [
  { id: "1", data: [4, 5, 1, 2, 3, 3, 2], area: true, stack: "1" },
  { id: "2", data: [7, 4, 6, 7, 2, 3, 5], area: true, stack: "1" },
  { id: "3", data: [6, 4, 1, 2, 6, 3, 3], area: true, stack: "1" },
  { id: "4", data: [4, 7, 6, 1, 2, 7, 7], area: true, stack: "1" },
  { id: "5", data: [2, 2, 1, 7, 1, 5, 3], area: true, stack: "1" },
  { id: "6", data: [6, 6, 1, 6, 7, 1, 1], area: true, stack: "1" },
  { id: "7", data: [7, 6, 1, 6, 4, 4, 6], area: true, stack: "1" },
  { id: "8", data: [4, 3, 1, 6, 6, 3, 5], area: true, stack: "1" },
  { id: "9", data: [7, 6, 2, 7, 4, 2, 7], area: true, stack: "1" },
].map((item, index) => ({
  ...item,
  color: mangoFusionPalette("light")[index],
}));

export const Trends = () => {
  // const [series, setSeries] = React.useState(defaultSeries);
  // const [wxSeries, setWxSeries] = React.useState([4, 5, 1, 2, 3, 3, 2]);
  // const [wtSeries, setWtSeries] = React.useState([7, 4, 6, 7, 2, 3, 5]);
  // const [nbSeries, setNbSeries] = React.useState(3);
  const [skipAnimation, setSkipAnimation] = React.useState(false);

  const [xAxisData, setXAxisData] = React.useState([]);
  const [dewPoints, setDewPoints] = React.useState([]);
  const [tempPoints, setTempPoints] = React.useState([]);
  const [windSpeeds, setWindSpeeds] = React.useState([]);
  const [windGuests, setWindGuests] = React.useState([]);
  const [windDirs, setWindDirs] = React.useState([]);
  const [baromaters, setBaromaters] = React.useState([]);
  const [isloaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    getTrendsData();
  }, []);

  const getTrendsData = async () => {
    const trendsData = await getWeatherTrends();
    let tmpXAxis = [];
    let tmpWSpeeds = [];
    let tmpDews = [];
    let tmpWGuests = [];
    let tmpTemps = [];
    let tmpWDirs = [];
    let tmpBarometers = [];
    trendsData.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return aDate.getTime() - bDate.getTime();
    });
    trendsData.map((tItem) => {
      const itemDate = new Date(tItem.date);
      

      // tmpXAxis.push(`${itemDate.getHours()}:${itemDate.getMinutes()}`);
      tmpXAxis.push(itemDate);
      tmpDews.push(tItem.dewPoint);
      tmpTemps.push(tItem.tempf);
      tmpWSpeeds.push(tItem.windspeedmph);
      tmpWGuests.push(tItem.windgustmph);
      tmpWDirs.push(tItem.winddir);
      tmpBarometers.push(tItem.baromrelin);
    });
    setXAxisData(tmpXAxis);
    setDewPoints(tmpDews);
    setTempPoints(tmpTemps);
    setWindSpeeds(tmpWSpeeds);
    setWindGuests(tmpWGuests);
    setWindDirs(tmpWDirs);
    setBaromaters(tmpBarometers);
    setIsLoaded(true);
  };
  const mainButtonStyle = {
    color: "white",
    textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
  };
  return (
    <div>
      <Box paddingY={5}>
        <Container>
          <Box>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              item
              xs={12}
            >
              {/* Left Item - Back Button */}
              <Grid item xs={6} md="auto">
                <Link href="/">
                  <Button variant="text">
                    <ChevronLeftIcon />
                    Back To Home
                  </Button>
                </Link>
              </Grid>

              {/* Center Item - Title (will wrap on small screens) */}
              <Grid
                item
                xs={12}
                md="auto"
                sx={{
                  order: { xs: 2, md: 0 },
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="h3">
                  WEATHER DATA
                </Typography>
              </Grid>

              {/* Right Item - Dark Button */}
              <Grid item xs={6} md="auto" sx={{ textAlign: "right" }}>
                <DarkButton mainButtonStyle={mainButtonStyle} />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box>
        <Grid container spacing={3}>
          {!isloaded && (
            <Grid width="100%">
              <Stack
                direction="row"
                width="100%"
                height={400}
                spacing={10}
                alignItems="center"
                justifyContent="center"
                useFlexGap
              >
                <CircularProgress />
              </Stack>
            </Grid>
          )}
          {isloaded && (
            <>
              <Grid item xs={12}>
                <LineChart
                  xAxis={[{ scaleType: "point", data: xAxisData }]}
                  series={[
                    { data: tempPoints, label: "TEMPERATURE", showMark: false },
                    { data: dewPoints, label: "DEW POINT", showMark: false },
                  ]}
                  skipAnimation={skipAnimation}
                  height={400}
                />
              </Grid>
              <Grid item xs={12}>
                <LineChart
                  xAxis={[{ scaleType: "point", data: xAxisData }]}
                  series={[
                    { data: windSpeeds, label: "WIND SPEED", showMark: false },
                    { data: windGuests, label: "WIND GUST", showMark: false },
                  ]}
                  skipAnimation={skipAnimation}
                  height={400}
                />
              </Grid>
              <Grid item xs={12}>
                <LineChart
                  xAxis={[{ scaleType: "point", data: xAxisData }]}
                  yAxis={[{ min: 1, max: 360, tickNumber: 20 }]}
                  series={[
                    {
                      data: windDirs,
                      label: "WIND DIRECTION",
                      showMark: false,
                    },
                  ]}
                  skipAnimation={skipAnimation}
                  height={400}
                />
              </Grid>
              <Grid item xs={12}>
                <LineChart
                  xAxis={[{ scaleType: "point", data: xAxisData }]}
                  yAxis={[{ tickNumber: 25 }]}
                  series={[
                    {
                      data: baromaters,
                      label: "BAROMENTIC PRESSURE",
                      showMark: false,
                    },
                  ]}
                  skipAnimation={skipAnimation}
                  height={400}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </div>
  );
};
