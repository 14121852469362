import Carousel from "react-material-ui-carousel";
import { Paper, Modal, Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { ArrowRight, Pause, SkipNext } from "@material-ui/icons";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import FastForwardIcon from "@mui/icons-material/FastForward";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: { xs: "95%", sm: "unset", md: "max-content" },
  maxWidth: "100vw",
  maxHeight: "100vh",
  bgcolor: "black",
  border: {
    md: "20px solid #fff",
    xs: "10px solid #fff",
    lg: "25px solid #fff",
  },
  boxShadow: 24,
  p: 0,
  borderRadius: 2,
  overflow: "hidden",
};

const carouselTxt = {
  textAlign: "center",
  position: "absolute",
  bottom: "20px",
  width: "100%",
  color: "white",
  textShadow: " -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
};

export const Timelapse = ({
  imagesArr,
  timelapsOpen,
  handleTimelapsClose,
  activeIndex,
  setActiveIndex,
  playc,
  setPlayc,
}) => {
  const hostPath = "https://denalicams.com";
  const [reversedImages, setReversedImages] = useState([]);

  const play = () => setPlayc((prev) => !prev);
  const handleFirst = () => setActiveIndex(0);

  useEffect(() => {
    if (imagesArr?.length) {
      setReversedImages([...imagesArr].reverse()); // Reverse and store in state
    }
  }, [imagesArr]);
  const handlePrev = () =>
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? imagesArr.length - 1 : prevIndex - 1
    );
  const handleNext = () =>
    setActiveIndex((prevIndex) =>
      prevIndex === imagesArr.length - 1 ? 0 : prevIndex + 1
    );
  const handleLast = () => setActiveIndex(imagesArr.length - 1);

  return (
    <Modal
      keepMounted
      open={timelapsOpen}
      onClose={handleTimelapsClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Carousel
          index={activeIndex}
          indicators={true}
          navButtonsAlwaysVisible
          animation="fade"
          duration={100} // Ensures smooth fade
          interval={400}
          autoPlay={playc}
          cycleNavigation={false}
          sx={{
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100vh",
            paddingBottom: "20px",
          }}
          onChange={(index) => {
            if (index !== null) {
              setActiveIndex(index);
            }
          }}
        >
          {reversedImages.map((imgItem, key) => (
            <Paper
              key={key}
              sx={{
                textAlign: "center",
                height: "100%",
                width: "100%",
                boxShadow: 0,
                position: "relative",
                backgroundColor: "black", // Keep the rest transparent
              }}
            >
              <Box
                sx={{
                  backgroundColor: "black", // Only this box will have a black background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  // maxHeight: "80vh",
                }}
              >
                <Box
                  component="img"
                  alt=""
                  src={`${hostPath}${imgItem.path}`}
                  className="fade-through-white"
                  sx={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "80vh",
                    objectFit: "contain",
                    backgroundColor: "black", // Ensure no white background is applied to the image
                    boxShadow: 0,
                    zIndex: 5,
                  }}
                />
              </Box>
              <p className="legend" style={carouselTxt}>
                {imgItem.modified}
              </p>
            </Paper>
          ))}
        </Carousel>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{
            mt: 2,
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "white",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Button
            variant="contained"
            style={{ background: "gray" }}
            onClick={play}
          >
            {!playc ? <PlayArrowIcon /> : <Pause />}
          </Button>
          <Button
            variant="contained"
            onClick={handleFirst}
            disabled={activeIndex === 0}
            style={{ background: "gray" }}
          >
            <FastRewindIcon />
          </Button>
          <Button
            variant="contained"
            onClick={handlePrev}
            disabled={activeIndex === 0}
            style={{ background: "gray" }}
          >
            <SkipPreviousIcon />
          </Button>
          <Button
            variant="contained"
            disabled={activeIndex === imagesArr.length - 1}
            onClick={handleNext}
            style={{ background: "gray" }}
          >
            <SkipNext />
          </Button>
          <Button
            variant="contained"
            onClick={handleLast}
            disabled={activeIndex === imagesArr.length - 1}
            style={{ background: "gray" }}
          >
            <FastForwardIcon />
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
